import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { IResponseValidaContrasena, Validacion } from 'src/app/models/administracion-usuarios/administracion-usuarios.model';
import { AdministrarUsuariosService } from 'src/app/services/administracion-usuarios/administrar-usuarios.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import Swal from 'sweetalert2';
import { SnackbarMsmComponent } from '../registro-asegurado/registro-asegurado.component';
import ValidationRegistro from '../validators/registro.validators';

@Component({
  selector: 'app-registro-proveedor',
  templateUrl: './registro-proveedor.component.html',
  styleUrls: ['./registro-proveedor.component.scss']
})
export class RegistroProveedorComponent implements OnInit {
  @Output() evento = new EventEmitter();
  imagen: string;
  loading = false;
  hide = true;
  todoFormulario: FormGroup;
  subsFormPassword$: Subscription;
  generaErrorPasswordForm = false;
  validandoPassword = false;
  haserror=true;
  errores: any = '';
  usuarioValido = { is: false, pending: false}

  catPreguntas: [] = [];
  catTipoPersona = [
    { Text: 'Fisica', Value: 1, Selected: true },
    { Text: 'Moral', Value: 2, Selected: false }
  ]

  constructor(private authService: AuthService,
    private snackBar: MatSnackBar,
    private adminService: AdministrarUsuariosService) { }

  ngOnInit(): void {
    this.imagenAleatoria();
    this.instaciarFiltros();
    this.cataPreguntas();

    this.subsFormPassword$ = this.todoFormulario.get('Password').valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe((pass: string) => {
        if (pass.length > 0) this.validarContrasena(pass);
      });
      this.todoFormulario.get('Usuario').valueChanges.pipe(
        tap( usuario =>{
          this.usuarioValido.pending = true;
          this.usuarioValido.is = false;
        }),
        debounceTime(800),
      ).subscribe((usuario: string) => {
        if(usuario.length > 0) this.validarUsuario(usuario)
          else {
            this.usuarioValido.pending = false;
            this.usuarioValido.is = false;
          }
      });
  }

  cataPreguntas() {
    this.authService.catalogoPreguntas().subscribe(resp => {
      this.catPreguntas = Object.assign(resp);
    })
  }

  registrar() {
    if (this.generaErrorPasswordForm) return this.abrirSnackBar('La contraseña de tener un formato correcto', `Contraseña`, 'exitoSnackbar');
    console.log(this.todoFormulario.getRawValue());

    
    this.authService.registrarProveedor(this.todoFormulario.getRawValue()).subscribe(resp => {
      Swal.fire({
        icon: 'success',
        title: 'Usuario registrado',
        text: 'El proveedor ' + this._Usuario.value + ' Se registro exitosamente',
        confirmButtonText: 'Aceptar',
      });
      this.regresar();
    }, (err) => {
      this.errores = '';
      if (err?.error?.Message) this.errores = err.error.Message;
      if (err?.error && Array.isArray(err?.error)) {
        err.error.forEach(element => {
          this.errores = `${this.errores}${element.Exception} <br>`
        });
      }
      Swal.fire({
        icon: 'error',
        title: 'Error al registrar',
        html: this.errores,
        confirmButtonText: 'Aceptar',
      });
    });
  }

  instaciarFiltros() {
    this.todoFormulario = new FormGroup({
      NIP: new FormControl('', Validators.required),
      Usuario: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_]*$/), Validators.minLength(4), Validators.maxLength(8)]),
      Cuenta: new FormControl(null, Validators.required),
      Telefono: new FormControl(null, [Validators.required, Validators.minLength(12), Validators.maxLength(12)]),
      Email: new FormControl(null, [Validators.required, Validators.email, Validators.pattern('[^@]+@[^@]+\.[a-zA-Z]{2,}$')]),
      Alias: new FormControl('', Validators.required),
      IdPregunta: new FormControl(0, Validators.required),
      Respuesta: new FormControl('', Validators.required),
      Nombre: new FormControl('', Validators.required),
      Paterno: new FormControl('', Validators.required),
      Materno: new FormControl('', Validators.required),
      RazonSocial: new FormControl(''),
      RFC: new FormControl('', [Validators.required, 
        Validators.pattern("^([A-Za-zÑñ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Za-z0-9]{3})?$"),
        Validators.maxLength(13)
      ]),
      TipoPersona: new FormControl(1, Validators.required),
      Password: new FormControl(null, [Validators.required]),
      ConfirmaPassword: new FormControl(null, [Validators.required]),
    }, {
      validators : ValidationRegistro.match('Password', 'ConfirmaPassword')
    });
  }

  password(formGroup: FormGroup) {
    const { value: password } = formGroup.get('Password');
    const { value: confirmpassword } = formGroup.get('ConfirmaPassword');
    if(password===confirmpassword){
      if (formGroup.get('ConfirmaPassword').hasError('match')) {
        delete formGroup.get('ConfirmaPassword').errors['match'];
      }
      if (Object.keys(formGroup.get('ConfirmaPassword').errors || {}).length === 0) {
        formGroup.get('ConfirmaPassword').setErrors(null);
      }
    }else{
      formGroup.get('ConfirmaPassword').setErrors({"match":true})
    }
  }

  imagenAleatoria() {
    this.imagen = `assets/img/${Math.floor((Math.random() * Math.floor(5)) + 1)}.jpg`;
    setTimeout(() => {
      this.imagenAleatoria();
    }, 30000);
  }
  regresar() {
    this.evento.emit(true);
  }

  getNullValue() {
    if (this._TipoPersona?.value == 1) {
      this.resetFormValidators(["RazonSocial"]);
      this.setFormValidators(["Nombre", "Paterno", "Materno"]);
    }
    if (this._TipoPersona?.value == 2) {
      this.resetFormValidators(["Nombre", "Paterno", "Materno"]);
      this.setFormValidators(["RazonSocial"]);
    }
  }

  setFormValidators(arr : any[]) {
    arr.forEach(control => {
      this.todoFormulario.get(control).reset();
      this.todoFormulario.get(control).setValidators(Validators.required);
      this.todoFormulario.get(control).updateValueAndValidity();
    });
  }

  resetFormValidators(arr : any[]) {
    arr.forEach(control => {
      this.todoFormulario.get(control).reset();
      this.todoFormulario.get(control).clearValidators();
      this.todoFormulario.get(control).updateValueAndValidity();
    });
  }


  validarContrasena(password: string) {
    let msgError = '';
    this.validandoPassword = true;
    const request = { password, usuario: "" };
    this.adminService.postValidaContrasena(request).subscribe((resp: IResponseValidaContrasena) => {
      this.validandoPassword = false;
      if (resp?.datos.length > 0) {
        this.generaErrorPasswordForm = true;
        this.procesarErroresPassword(resp.datos);
        document.getElementById('iconPass').style.color = '#8395a7';
      } else {
        this.generaErrorPasswordForm = false;
        document.getElementById('iconPass').style.color = '#10ac84';
      }
    }, error => {
      this.validandoPassword = false;
      this.generaErrorPasswordForm = false;
      const err = error.error || [];
      if (!err.length) msgError = "Ups, se generó un error";
      err.forEach((element, index) => {
        if (element.Visible) msgError = index === 0 ? element.Exception : `${msgError} ${element.Exception}`;
        if (element.password) msgError = element.password[0];
      });
      document.getElementById('iconPass').style.color = '#8395a7';
      this.abrirSnackBar(msgError, `Contraseña`, 'errorSnackbar');
    });
  }

  procesarErroresPassword(array: Validacion[]) {
    let msgError = "";
    array.forEach((element) => {
      if (array.length === 1)
        msgError += `<li> ${element.Descripcion} </li>`;
      else
        msgError += `<li> ${element.Descripcion} </li> <br>`;
    });
    this.abrirSnackBar(msgError, `Contraseña`, 'exitoSnackbar');
  }

  validarUsuario(usuario:string){
    const validacionUsuario = this.obtenerErrores('Usuario');
     if(validacionUsuario ==''){
       this.authService.validaUsuario(usuario).subscribe(x => {
        
        this.authService.validaUsuarioSisa(usuario).subscribe(x => {
          this.usuarioValido.is = true;
          this.usuarioValido.pending = false;
  
        }, error => {
          this.usuarioValido.is = false;
          this.usuarioValido.pending = false;
        });
        
       }, error => {
         this.usuarioValido.is = false;
         this.usuarioValido.pending = false;
       });
    }else{
     this.usuarioValido.is = false;
     this.usuarioValido.pending = false;
    }
  }
    obtenerErrores(field) {
      const control =  this.todoFormulario.get(field);
      let  error ='';
          error = control.hasError('required') ? 'El campo es obligatorio':
                  control.hasError('maxlength')? 'El campo solo puede tener hasta 8 caracteres':
                  control.hasError('pattern')  ? 'El campo debe ser alfanumérico (A-Z,a-z,0-9)':''  
      return error;
    }
  abrirSnackBar(mensaje: string, titulo: string, clase: string) {
    this.snackBar.openFromComponent(SnackbarMsmComponent, {
      duration: 5000,
      panelClass: clase,
      data: {
        html: mensaje
      }
    })
  }

  get _Usuario(): FormControl {
    return this.todoFormulario.get('Usuario') as FormControl || null;
  }

  get _TipoPersona(): FormControl {
    return this.todoFormulario.get('TipoPersona') as FormControl || null;
  }

  get _RazonSocial(): FormControl {
    return this.todoFormulario.get('RazonSocial') as FormControl || null;
  }

}
